export const UPLOAD_CSV_LABEL = 'Subir CSV'
export const ADD_PROFESSIONAL_LABEL = 'Añadir profesional'
export const NO_PROFESSIONALS_UPLOADED = 'No hay personal cargado'
export const NO_PROFESSIONALS_UPLOADED_SUBTITLE = 'todavía no tienes personal subido en el portal, para subirlo puedes descargar nuestra plantilla y subir el csv para hacer una carga masiva.';
export const DOWNLOAD_CSV_TEMPLATE_LABEL = 'Descargar plantilla CSV'
export const ERRORS_FOUND_TITLE = 'Se han encontrado los siguientes errores:'
export const PROFESSIONAL_ADDED_CORRECTLY = 'Profesional añadido correctamente'
export const PROFESSIONAL_ADDED = 'Profesional añadido'
export const PROFESSIONALS_PAGE_TITLE = 'Personal';
export const PROFESSIONAL_TABLE_NAME_HEADER = 'Profesional';
export const PROFESSIONAL_TABLE_EMPLOYEE_NUMBER_HEADER = 'Número de empleado';
export const PROFESSIONAL_TABLE_UNIT_HEADER = 'Unidad';
export const PROFESSIONAL_TABLE_CONTRACT_HEADER = 'Contrato';
export const SAVE_CHANGES_LABEL = 'Guardar cambios'
export const CANCEL_LABEL = 'Cancelar'
export const EMPLOYEE_NUMBER_LABEL = 'Número de empleado'
export const UNIT_LABEL = 'Unidad'
export const CONTRACT_TYPE_LABEL = 'Contrato'
export const NO_REQUESTS_TITLE= 'No hay solicitudes pendientes'
export const NO_PROFESSIONALS_TITLE = 'No hay profesionales'
export const NO_PROFESSIONALS_SUBTITLE = 'De momento no tienes profesionales inscritos en tu centro.'
export const SEARCH_PLACEHOLDER = 'Buscar...';