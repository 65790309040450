import { ClaimRequest, ClaimStatusEnunm, SlotReason } from "types/claims"
import { ProfessionalCardHeader } from "./ProfessionalCardHeader";
import { ProfessionalFacilityExperience } from "./ProfessionalFacilityExperience";
import { ProfessionalLivoReviews } from "./ProfessionalLivoReviews";
import LivoIcon from "assets/LivoLogo";
import { PENDING_CLAIMS_TITLE } from "locales/shiftClaimDetails/es";
import { ManageClaimContainer } from "./ManageClaimContainer";
import { ProfessionalDataBody } from "./ProfessionalProfileDataBody";
import { SlotReasonDetails } from "./SlotReasonDetails";

interface ProfessionalClaimDetailsProps {
    claim: ClaimRequest;
    goBack: () => void;
    onAccept: () => void;
    onReject: () => void;
    shiftId: number;
    onUpdateSlotReason: (slotReason: SlotReason) => void;
}
export const ProfessionalClaimDetails: React.FC<ProfessionalClaimDetailsProps> = ({
    claim,
    goBack,
    onAccept,
    onReject,
    shiftId,
    onUpdateSlotReason
}) => {


    return (
        <div
            className="flex flex-col w-full justify-between pb-large"
        >
            <button
                onClick={goBack}
                className="flex w-full space-x-small items-center justify-start px-small mb-small"
            >
                <LivoIcon name='arrow-left' size={24} color='#ACBBCB' />
            </button>
            <div
                className="flex flex-col w-full  overflow-y-scroll no-scrollbar"

            >

                <div
                    className="flex flex-col w-full space-y-large divide-y-[1px] divide-Divider-Default"
                >
                    <div
                        className="flex flex-col w-full"
                    >
                        <ProfessionalCardHeader
                            professionalProfile={claim.professionalProfile}
                            modality={claim.modality}
                        />
                        <ProfessionalDataBody claim={claim} />
                    </div>

                    <ProfessionalFacilityExperience
                        professionalProfile={claim.professionalProfile}
                    />
                    {claim.professionalProfile.professionalReview?.averageRating ? <ProfessionalLivoReviews
                        professionalProfile={claim.professionalProfile}
                    /> : null}
                    {
                        claim.status !== ClaimStatusEnunm.PENDING_APPROVAL && claim.slotReasonOptions && claim.slotReasonOptions.length > 0 ? <SlotReasonDetails
                            onUpdateSlotReason={onUpdateSlotReason}
                            slotReason={claim.slotReason}
                            slotReasonOptions={claim.slotReasonOptions}
                            claimId={claim.id}
                            shiftId={shiftId}
                            slotReasonCommentDisplayed={claim.slotReasonCommentDisplayed}
                        /> : null
                    }
                </div>

            </div>
            {claim.status === ClaimStatusEnunm.PENDING_APPROVAL ?
                <div
                    className="flex flex-col w-full px-medium"
                >
                    <ManageClaimContainer
                        onAccept={onAccept}
                        onReject={onReject}
                    >
                    </ManageClaimContainer>
                </div>
                : null}

        </div>
    )
}