import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom"
import { ShiftClaimListScreen } from "./ShiftClaimListScreen"
import { ShiftClaimDetailsPage } from "./ShiftClaimDetailsPage"
import { InternalProfessionalsPage } from "./InternalProfessionalsPage"
import { Box, CircularProgress } from "@mui/material"
import { AccountInfo, VisibleTabEnum, fetchAccountInfo } from "services/account"
import React, { useEffect, useState } from "react"
import Sidebar from "components/layout/Sidebar"
import { Calendar } from "./Calendar"
import { PublishedShiftList } from "./PublishedShiftList"
import { ActivityShiftList } from "./ActivityShiftList"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "store/types"
import { setAccountInfo } from "store/actions/accountActions"
import { fetchActivity } from "store/actions/activityShiftListActions"
import { AppDispatch } from "store"
import NoInternetPage from "./NoInternetPage"
import { showToastAction, toggleInternetConnection } from "store/actions/appConfigurationActions"
import { ApiApplicationError } from "services/api"
import posthog from 'posthog-js';
import { FacilityStaffPage } from "./FacilityStaffPage"
import { AccountModal } from "components/account/AccountModal"
import { UNITS_LABEL } from "locales/facilitystaff"
import { CATEGORIES_LABEL } from "locales/shiftClaimDetails/es"
import { setFilters } from "store/actions/filtersActions"
import { ShiftFilter } from "types/common/shiftFilters"


interface RouterComponentProps {
    logOut: () => void;
    accountModalOpen: boolean;
    setAccountModalOpen: (open: boolean) => void;
}

export type RouteProps = {
    name: string;
    path: string;
    iconName: string;
    component: React.FC;
    id: VisibleTabEnum;
    notifications?: number;

}

export const RouterComponent: React.FC<RouterComponentProps> = ({ 
    logOut,
    accountModalOpen,
    setAccountModalOpen
}) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch<AppDispatch>()
    const { accountInfo } = useSelector((state: RootState) => state.account)
    const { internetConnection } = useSelector((state: RootState) => state.appConfiguration)
    const routes = [
        { name: 'Actividad', path: '/actividad', iconName: 'bell', component: ActivityShiftList, id: VisibleTabEnum.ACTIONABLE_SHIFTS },
        { name: 'calendar', path: '/calendar', iconName: 'calendar', component: Calendar, id: VisibleTabEnum.CALENDAR },
        { name: 'Turnos publicados', path: '/turnos-publicados', iconName: 'report-medical', component: PublishedShiftList, id: VisibleTabEnum.NEXT_SHIFTS_LIST },
        { name: 'Shifts', path: '/documentacion', iconName: 'file-text', component: ShiftClaimListScreen, id: VisibleTabEnum.LEGAL_DOCUMENTATION },
        { name: 'Internal Professionals', path: '/internal-professionals', iconName: 'users', component: InternalProfessionalsPage, id: VisibleTabEnum.INTERNAL_STAFF_MANAGEMENT },
        { name: 'Facility Staff', path: '/facility-staff', iconName: 'adjustments-alt', component: FacilityStaffPage, id: VisibleTabEnum.FACILITY_STAFF_MANAGEMENT }
    ] as RouteProps[]

    const filteredRoutes = routes.filter((route) => accountInfo?.visibleTabMenu.includes(route.id))

    const loadAccountInfo = async () => {
        await fetchAccountInfo().then((response: AccountInfo) => {
            dispatch(setAccountInfo(response))
            storeAccountFilters(response)
            posthog.identify(
                response.id,
                { email: response.email, name: `${response.firstName} ${response.lastName}` }
            );
        }).catch((error) => {
            if (error instanceof ApiApplicationError) {
                if (error.cause === 'NO_INTERNET') {
                    dispatch(toggleInternetConnection(false))
                } else {
                    dispatch(showToastAction({
                        message: error.message,
                        severity: 'error'
                    }))
                }
            }
        })
        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)
        loadAccountInfo()
        dispatch(fetchActivity())
    }, [])

    if (loading) {
        return (<Box sx={{ display: 'flex', flex: 1, alignItems: 'center', minHeight: '30em', minWidth: '100%', justifyContent: 'center' }}>
            <CircularProgress />
        </Box>)
    }

    function storeAccountFilters(accountInfo: AccountInfo) {

        const units = accountInfo.units ?? [];
        const categories = accountInfo.facility.categories ?? [];

        const shiftFilters = [] as ShiftFilter[];
        if (units.length > 0) {
            shiftFilters.push({
                key: 'unit',
                name: UNITS_LABEL,
                options: units,
                selectedOptions: [],
                appliedOptions: []
            });
        }
        if (categories.length > 0) {
            shiftFilters.push({
                key: 'category',
                name: CATEGORIES_LABEL,
                options: categories,
                selectedOptions: [],
                appliedOptions: [],
            });
        }

        dispatch(setFilters(shiftFilters));
    }


    return (
        <Router>
            <div className="flex overflow-y-hidden no-scrollbar"
            >
                <Sidebar
                    logOut={logOut}
                    routes={filteredRoutes}
                />
                <div className="flex-1 content overflow-x-auto">
                    {internetConnection ? <Routes>
                        {
                            filteredRoutes
                                .map((route, index) => {
                                    return (
                                        <Route path={route.path} Component={route.component} key={index} />
                                    )
                                })
                        }
                        {filteredRoutes.length > 0 && <Route path="/" element={<Navigate to={filteredRoutes[0].path} />} />}
                        {filteredRoutes.some((route: RouteProps) => route.id === VisibleTabEnum.LEGAL_DOCUMENTATION) && <Route path="/shift-claim/:id" Component={ShiftClaimDetailsPage} />}
                    </Routes> :
                        <NoInternetPage />
                    }
                </div>

            </div>
            <AccountModal
                isOpen={accountModalOpen}
                onClose={() => {
                    setAccountModalOpen(false);
                }}
            />
        </Router>
    )
}
