import LivoIcon from "assets/LivoLogo";
import { InfoSection } from "./InfoSection";
import { useSelector } from "react-redux";
import { RootState } from "store/types";
import { ChangePasswordComponent } from "./ChangePassword";
import { useState } from "react";
import { CONFIGURATION_SCREEN_TITLE, LAST_TIME_SIGN_IN, SETTINGS_SCREEN_CHANGE_PASSWORD_HEADER, SETTINGS_SCREEN_FACILITY_INFO, SETTINGS_SCREEN_INFORMATION, SETTINGS_SCREEN_SETTINGS_CONFIG } from "locales/settings";
import { ModalContainer } from "components/common/ModalContainer";
import { TagLabel } from "components/common/TagLabel";
import moment from "moment";

interface AccountModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const AccountModal: React.FC<AccountModalProps> = ({
    isOpen,
    onClose
}) => {
    const isOpenClass = isOpen ? 'block' : 'hidden';
    const { accountInfo } = useSelector((state: RootState) => state.account)
    const [isChangingPassword, setIsChangingPassword] = useState(false)




    return accountInfo && (
        <ModalContainer
            isOpen={isOpen}
            onClose={onClose}
        >
            <div
                className="flex flex-col overflow-y-auto bg-white rounded-[16px] mx-auto z-50  w-[448px]"
            >
                <div
                    className="flex flex-row justify-between items-center p-large border-b border-Divider-Default"
                >
                    {isChangingPassword ?
                        <div
                            className="flex items-center space-x-small"
                        >
                            <button
                                onClick={() => setIsChangingPassword(false)}
                            >
                                <LivoIcon name='arrow-left' size={24} color='#707A91' />
                            </button>
                            <p
                                className="heading-md"
                            >
                                {SETTINGS_SCREEN_CHANGE_PASSWORD_HEADER}
                            </p>
                        </div> :
                        <p
                            className="heading-md"
                        >
                            {CONFIGURATION_SCREEN_TITLE}
                        </p>}
                    <button
                        onClick={() => {
                            setIsChangingPassword(false)
                            onClose()
                        }}
                        className="flex items-center justify-center"
                    >
                        <LivoIcon name='close' size={24} color='#707A91' />
                    </button>
                </div>
                {
                    isChangingPassword ? <ChangePasswordComponent
                        errorMessage=""
                        onSubmit={() => {
                            onClose();
                            setIsChangingPassword(false)
                        }} /> :
                        <div
                            className="flex flex-col p-large space-y-medium bg-Background-Secondary"
                        >
                            <InfoSection
                                title={SETTINGS_SCREEN_INFORMATION}
                                infoItems={[
                                    {
                                        iconName: 'user',
                                        label: `${accountInfo.firstName} ${accountInfo.lastName}`,
                                    },
                                    {
                                        iconName: 'mail',
                                        label: accountInfo.email,
                                    },

                                ]} />
                            <InfoSection
                                title={SETTINGS_SCREEN_FACILITY_INFO}
                                infoItems={[
                                    {
                                        iconName: 'internal-hospital',
                                        label: accountInfo.facility.name,
                                    },

                                    ...(accountInfo?.units?.length > 0
                                        ? [
                                            {
                                              iconName: 'nurse',
                                              children: (
                                                <div className="flex flex-wrap w-full">
                                                  {accountInfo.units.map((unit, index) => (
                                                    <div className="mr-tiny my-[2px]" key={index}>
                                                        <TagLabel  text={unit.displayName} />
                                                    </div>
                                                  ))}
                                                </div>
                                              ),
                                            },
                                          ]
                                        : []),

                                    {
                                        iconName: 'map',
                                        label: accountInfo.facility.address,
                                        onClick: () => {
                                            {
                                                window.open(accountInfo.facility.mapLink, '_blank', 'noopener,noreferrer');
                                            }
                                        }
                                    },
                                ]} />
                            <InfoSection
                                title={SETTINGS_SCREEN_SETTINGS_CONFIG}
                                infoItems={[
                                    {
                                        iconName: 'key',
                                        label: SETTINGS_SCREEN_CHANGE_PASSWORD_HEADER,
                                        onClick: () => {
                                            setIsChangingPassword(true)
                                        }
                                    },

                                ]} />

                        </div>
                }
                {accountInfo.lastTimeSignIn &&  !isChangingPassword  ? <p
                    className="info-caption text-Text-Subtle p-medium text-center"
                >
                    {LAST_TIME_SIGN_IN}{moment(accountInfo.lastTimeSignIn).format('DD/MM/YYYY HH:mm')}
                </p> : null}
            </div>
        </ModalContainer >

    )
}