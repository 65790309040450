
import { CalendarDayShifts } from "components/calendar/CalendarDayShifts"
import { CalendarSummary } from "components/calendar/CalendarSummary"
import { ShiftDetailsSection } from "components/shiftDetails/ShiftDetailsSection"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { ShiftSummary, fetchShifts, fetchShiftsSummary } from "services/shifts-calendar"
import { setDateSelected, setSelectedShiftId } from "store/actions"
import { RootState } from "store/types"
import { Shift } from "types/shifts"
import { showToastAction, toggleInternetConnection } from "store/actions/appConfigurationActions"
import { ApiApplicationError } from "services/api"
import FilterShiftsModal from "components/shiftlist/FilterShiftsModal"
import { getOptionToValue } from "types/common/shiftFilters"

export const Calendar: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [loadingShifts, setLoadingShifts] = useState(true);
    const [shifts, setShifts] = useState<Shift[]>([]);
    const { selectedDate, selectedShiftId } = useSelector((state: RootState) => state.calendarSummary);
    const [shiftSummary, setShiftSummary] = useState<ShiftSummary[]>([]);
    const [loadingShiftSummary, setLoadingShiftSummary] = useState(true);
    const [holiday, setHoliday] = useState<boolean>(false);
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const { filters } = useSelector((state: RootState) => state.filterShifts);

    const filtersApplied = filters.reduce((acc, filter) => {
        acc[filter.key] = filter.appliedOptions.map(getOptionToValue(filter));
        return acc;
    }, {} as { [key: string]: string[] });
    const categoryFilter = filtersApplied['category'];
    const unitFilter = filtersApplied['unit'];
    const loadData = async () => {
        return fetchShifts(
            selectedDate,
            selectedDate,
        ).then((response) => {
            setShifts(response.length ? response[0].shifts : []);
            setHoliday(response.length ? response[0].holiday || false : false);
        }).catch((error) => {
            dispatch(setSelectedShiftId(null))
            if (error instanceof ApiApplicationError) {
                if (error.cause === 'NO_INTERNET') {
                    dispatch(toggleInternetConnection(false))
                } else {
                    dispatch(showToastAction({
                        message: error.message,
                        severity: 'error'
                    }))
                }
            }
        });
    };

    const loadShifts = async () => {
        setLoadingShifts(true);
        await loadData().then(() => {
            setLoadingShifts(false);
        }).catch((error) => {
        });
    };

    const shadowLoadShiftSummary = async () => {
        return fetchShiftsSummary(
            undefined,
            undefined,
            categoryFilter,
            unitFilter
        ).then((response) => {
            setShiftSummary(response);
        }).catch((error) => {
        })
    };

    const loadShiftsSummary = async () => {
        setLoadingShiftSummary(true);
        shadowLoadShiftSummary().then(() => {
            setLoadingShiftSummary(false);
        }).catch((error) => {

        })
    };
    useEffect(() => {
        loadShiftsSummary();
    }, [ categoryFilter?.join(','), unitFilter?.join(',')]);

    useEffect(() => {
        loadShifts();
        if (selectedDate) {
            const newUrl = `${location.pathname}?date=${selectedDate}`;
            const currentUrl = `${location.pathname}${location.search}`;
            if (newUrl !== currentUrl) {
                navigate(newUrl);
            }
            
        }
    }, [selectedDate]);

    return (
        <div className="content space-y-medium">
            <div className="flex h-full space-x-medium justify-between overflow-y-hidden">
                <CalendarSummary
                    onDayPress={(day: string) => {
                        dispatch(setDateSelected(day));
                    }}
                    shiftSummary={shiftSummary}
                    loading={loadingShiftSummary}
                    openFilter={() => setFilterModalOpen(true)}
                />
                <div className="flex flex-1 w-full">
                    <CalendarDayShifts
                        shifts={shifts}
                        date={selectedDate.toString()}
                        loading={loadingShifts}
                        reloadData={() => {
                            loadShifts();
                            shadowLoadShiftSummary();
                        }}
                        shadowReload={() => {
                            loadData();
                            shadowLoadShiftSummary();
                        }}
                        holiday={holiday}
                    />
                    <ShiftDetailsSection
                        reloadShifts={() => {
                            loadData();
                            shadowLoadShiftSummary();
                        }}
                        selectedShiftId={selectedShiftId}
                        setSelectedShiftId={(id: number | null) => {
                            dispatch(setSelectedShiftId(id));
                        }}
                    />
                </div>
            </div>
            <FilterShiftsModal
                isOpen={filterModalOpen}
                onClose={() => setFilterModalOpen(false)}
            />
        </div>
    );
};
